import {
  IGHGTargets,
  IGHGTargetsLongTerm,
  IGHGTargetsLongTermAndShortRequest,
  IGHGTargetsShortTerm,
} from "@/models/SettingBaseLine";
import { ErrorResponse } from "@/services/axios/error";
import { SettingBaseLineAPI } from "@/services/settingbaseline";
import store from "@/store";
import { shortTermAndLongTerm } from "@/utils/helpers/convertDataChart";
import { ElMessage } from "element-plus/lib/components/message";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
const name = "SettingBaseLineModule";

if (store.state[name]) {
  store.unregisterModule(name);
}
@Module({ dynamic: true, name, namespaced: true, store })
class SettingBaseLineModule extends VuexModule {
  private errorGHGTargets = {} as ErrorResponse;
  private errorSubmitGHGTargets = {} as ErrorResponse;

  private GHGTargets = {} as IGHGTargets;

  private GHGTargetsShortTerm = {} as IGHGTargetsShortTerm;
  private GHGTargetsLongTerm = {} as IGHGTargetsLongTerm;
  private GHGTargetsShortTermChart = [] as any;
  private GHGTargetsLongTermChart = [] as any;
  private isLoadingGHGTargets = false;
  private isLoadingSubmitGHGTargets = false;

  get GHGTargetsGetter() {
    return this.GHGTargets;
  }
  get isLoadingGHGTargetsGetter() {
    return this.isLoadingGHGTargets;
  }
  get GHGTargetsShortTermGetter() {
    return this.GHGTargetsShortTerm;
  }
  get GHGTargetsLongTermGetter() {
    return this.GHGTargetsLongTerm;
  }
  get GHGTargetsShortTermChartGetter() {
    return this.GHGTargetsShortTermChart;
  }
  get GHGTargetsLongTermChartGetter() {
    return this.GHGTargetsLongTermChart;
  }
  @Action({ rawError: true })
  async getGHGTargetsAction() {
    const serviceSettingBaseLineAPI = new SettingBaseLineAPI();
    this.setLoadingGHGTargets(true);
    const { data, error } = await serviceSettingBaseLineAPI.getGHGTargets();
    if (error) {
      this.getEnergyTypeError(error);
      return false;
    }
    this.getEnergyTypeSuccess(data);
    return data;
  }

  @Mutation
  getEnergyTypeSuccess(data: IGHGTargets) {
    this.GHGTargets = data;
    this.errorGHGTargets = {} as ErrorResponse;
    this.isLoadingGHGTargets = false;
  }
  @Mutation
  getEnergyTypeError(error: ErrorResponse) {
    this.GHGTargets = {} as IGHGTargets;
    this.errorGHGTargets = error;
    this.isLoadingGHGTargets = false;
  }

  @Mutation
  setLoadingGHGTargets(isLoading: boolean) {
    this.isLoadingGHGTargets = isLoading;
  }

  @Action({ rawError: true })
  async submitGHGTargetsAction(payload: IGHGTargets) {
    const serviceSettingBaseLineAPI = new SettingBaseLineAPI();
    this.setLoadingSubmitGHGTargets(true);
    const { data, error } = await serviceSettingBaseLineAPI.submitGHGTargets(
      payload
    );
    if (error) {
      this.submitGHGTargetsError(error);
    } else {
      this.submitGHGTargetsSuccess();
    }
  }

  @Mutation
  submitGHGTargetsSuccess() {
    ElMessage({
      message: "Submit Target success.",
      grouping: true,
      type: "success",
    });
    this.errorSubmitGHGTargets = {} as ErrorResponse;
    this.isLoadingSubmitGHGTargets = false;
  }
  @Mutation
  submitGHGTargetsError(error: ErrorResponse) {
    ElMessage.error(error.message);
    this.errorSubmitGHGTargets = error;
    this.isLoadingSubmitGHGTargets = false;
  }

  @Mutation
  setLoadingSubmitGHGTargets(isLoading: boolean) {
    this.isLoadingSubmitGHGTargets = isLoading;
  }

  @Action({ rawError: true })
  async getGHGTargetsShortTermAction(
    payload?: IGHGTargetsLongTermAndShortRequest
  ) {
    const serviceSettingBaseLineAPI = new SettingBaseLineAPI();
    this.setLoadingSubmitGHGTargets(true);
    const { data, error } =
      await serviceSettingBaseLineAPI.getGHGTargetsShortTerm(payload);
    if (error) {
      this.getGHGTargetsShortTermError(error);
    } else {
      this.getGHGTargetsShortTermSuccess(data);
    }
  }

  @Mutation
  getGHGTargetsShortTermSuccess(data: IGHGTargetsShortTerm) {
    this.errorSubmitGHGTargets = {} as ErrorResponse;
    this.GHGTargetsShortTerm = data;
    this.GHGTargetsShortTermChart = shortTermAndLongTerm(data);
  }
  @Mutation
  getGHGTargetsShortTermError(error: ErrorResponse) {
    this.errorSubmitGHGTargets = error;
    this.isLoadingSubmitGHGTargets = false;
  }

  @Action({ rawError: true })
  async getGHGTargetsLongTermAction(
    payload?: IGHGTargetsLongTermAndShortRequest
  ) {
    const serviceSettingBaseLineAPI = new SettingBaseLineAPI();
    this.setLoadingSubmitGHGTargets(true);
    const { data, error } =
      await serviceSettingBaseLineAPI.getGHGTargetsLongTerm(payload);
    if (error) {
      this.getGHGTargetsLongTermError(error);
    } else {
      this.getGHGTargetsLongTermSuccess(data);
    }
  }

  @Mutation
  getGHGTargetsLongTermSuccess(data: IGHGTargetsLongTerm) {
    this.errorSubmitGHGTargets = {} as ErrorResponse;
    this.GHGTargetsLongTerm = data;
    this.GHGTargetsLongTermChart = shortTermAndLongTerm(data);
  }
  @Mutation
  getGHGTargetsLongTermError(error: ErrorResponse) {
    this.errorSubmitGHGTargets = error;
    this.isLoadingSubmitGHGTargets = false;
  }
}

export default getModule(SettingBaseLineModule);
