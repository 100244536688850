import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";
import {
  IGHGTargets,
  IGHGTargetsLongTermAndShortRequest,
} from "@/models/SettingBaseLine";
const GHG_TARGETS = "GHGTargets";
const env = JSON.parse(localStorage.getItem("env") as string);
export class SettingBaseLineAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getGHGTargets = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GHG_TARGETS}`)
      .catch(catchAxiosError);

    return response;
  };
  public submitGHGTargets = async (data: IGHGTargets): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${GHG_TARGETS}`, data)
      .catch(catchAxiosError);

    return response;
  };
  public getGHGTargetsShortTerm = async (
    params?: IGHGTargetsLongTermAndShortRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GHG_TARGETS}/short-term`, { params })
      .catch(catchAxiosError);

    return response;
  };
  public getGHGTargetsLongTerm = async (
    params?: IGHGTargetsLongTermAndShortRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${GHG_TARGETS}/long-term`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
