import { IUploadScopeDTO } from "@/models/FormData";
import {
  IScopeOneScopeThreeHistoryRequest,
  IScopeOneScopeThreeParameters,
  IScopeOneScopeThreeRequestDetailParamsDTO,
  IScopeOneScopeThreeRequestTotal,
  IScopeOneScopeThreeRequestHistory,
} from "@/models/ScopeOneScopeThreeEmissions";
import { IScopeOneThreeChart } from "@/models/Scope1Scope3";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const SCOPE1 = "ScopeOneEmissions";
const SCOPE3 = "ScopeThreeEmissions";
const env = JSON.parse(localStorage.getItem("env") as string);
export class Scope1Scope3API extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }
  public uploadScopeOne = async (data: IUploadScopeDTO): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${SCOPE1}/${data.Year}/requests`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneRequest = async (
    params: IScopeOneScopeThreeParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/${params.Year}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneRequestDetail = async (
    params: IScopeOneScopeThreeRequestDetailParamsDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/${params.Year}/requests/${params.requestId}`)
      .catch(catchAxiosError);

    return response;
  };

  public uploadScopeThree = async (
    data: IUploadScopeDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${SCOPE3}/${data.Year}/requests`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeRequest = async (
    params: IScopeOneScopeThreeParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/${params.Year}/requests`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeRequestDetail = async (
    params: IScopeOneScopeThreeRequestDetailParamsDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/${params.Year}/requests/${params.requestId}`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneEmission = async (
    params: IScopeOneThreeChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/${params.Year}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeEmission = async (
    params: IScopeOneThreeChart
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/${params.Year}`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneTotal = async (
    params: IScopeOneScopeThreeRequestTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/${params.Year}/total/${params.FacilityId}`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeTotal = async (
    params: IScopeOneScopeThreeRequestTotal
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/${params.Year}/total/${params.FacilityId}`)
      .catch(catchAxiosError);

    return response;
  };

  public getScopeOneHistory = async (
    params: IScopeOneScopeThreeHistoryRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE1}/history`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getScopeThreeHistory = async (
    params: IScopeOneScopeThreeHistoryRequest
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${SCOPE3}/history`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
