import { IEmissionPostDTO, IEmissionWorldMapDTO } from "@/models/Emission";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const EMISSION = "Emissions";
const env = JSON.parse(localStorage.getItem("env") as string);
export class EmissionAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getEmission = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${EMISSION}`)
      .catch(catchAxiosError);
    return response;
  };

  public getYearEmission = async (
    params: string | number | Date
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/${EMISSION}/${params}`)
      .catch(catchAxiosError);

    return response;
  };

  public getEmissionYear = async (
    data: string | number | Date
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/${EMISSION}/years/${data}`)
      .catch(catchAxiosError);

    return response;
  };

  public createEmissionYear = async (
    data: IEmissionPostDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${EMISSION}/years/${data.Year}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getYearsEmission = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/${EMISSION}/years/`)
      .catch(catchAxiosError);

    return response;
  };

  public getWorldMapEmission = async (
    params: IEmissionWorldMapDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`/${EMISSION}/years/${params.Year}/worldMap`, { params })
      .catch(catchAxiosError);

    return response;
  };
}
