import {
  IScopeOneThreeChart,
  IScopeOneThreeDataChart,
} from "@/models/Scope1Scope3";
import { IScopeOneScopeThreeHistoryRequest } from "@/models/ScopeOneScopeThreeEmissions";
import { ErrorResponse } from "@/services/axios/error";
import { Scope1Scope3API } from "@/services/scope1scope3";
import store from "@/store";
import { covertDataChartScope1And3 } from "@/utils/helpers/convertDataChart";
import { getYear } from "@/utils/helpers/convertDateTime";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from "vuex-module-decorators";
import ScopeTwoEmissionModule from "@ems/containers/ScopeTwoEmission/ScopeTwoEmission.module";
import EmissionModule from "@ems/containers/Emission/Emission.module";
import SettingBaseLineModule from "@ems/containers/SettingInformation/SettingBaseLine.module";
const name = "ScopeOneAndThreeEmission";

if (store.state[name]) {
  store.unregisterModule(name);
}

@Module({ dynamic: true, name, namespaced: true, store })
class ScopeOneAndThreeEmissionModule extends VuexModule {
  private error = {} as ErrorResponse;
  private errorScopeOne = {} as ErrorResponse;
  private errorScopeThree = {} as ErrorResponse;
  private isLoadingScopeOneAndThree = false as boolean;

  private dataScopeOne = [] as IScopeOneThreeDataChart[][];
  private dataScopeThree = [] as IScopeOneThreeDataChart[][];
  private dataScopeOneBase = {} as IScopeOneThreeDataChart;
  private dataScopeThreeBase = {} as IScopeOneThreeDataChart;
  private currentYear = new Date().getFullYear() as string | number | Date;
  private facility = "" as string;
  private dataTotalCO2ScopeOneTwoThree = 0 as any;
  @Mutation
  loadingScopeOneAndThreeEmission(isLoading = false as boolean) {
    this.isLoadingScopeOneAndThree = isLoading;
  }

  get dataScopeOneGetter() {
    return this.dataScopeOne;
  }
  get dataScopeOneBaseGetter() {
    return this.dataScopeOneBase;
  }
  get loadingScopeOneAndThreeGetter() {
    return this.isLoadingScopeOneAndThree;
  }

  @Mutation
  setScopeOneError(error: ErrorResponse) {
    this.errorScopeOne = error;
  }

  @Mutation
  setScopeOneSuccess(dataScopeOne: IScopeOneThreeDataChart[][]) {
    this.dataScopeOne = dataScopeOne;
  }
  @Mutation
  setScopeOneBaseSuccess(dataScopeOne: IScopeOneThreeDataChart) {
    this.dataScopeOneBase = dataScopeOne;
  }
  @Action({ rawError: true })
  async fetchScopeOne(payload: IScopeOneThreeChart) {
    const serviceScopeOneAndThreeAPI = new Scope1Scope3API();
    const { data, error } =
      await serviceScopeOneAndThreeAPI.getScopeOneEmission(payload);
    if (error) {
      this.setScopeOneError(error);
    } else {
      this.setScopeOneBaseSuccess(data);
      const dataConvert = covertDataChartScope1And3(data);
      return dataConvert;
    }
  }

  get dataScopeThreeGetter() {
    return this.dataScopeThree;
  }
  get dataScopeThreeBaseGetter() {
    return this.dataScopeThreeBase;
  }
  @Mutation
  setScopeThreeError(error: ErrorResponse) {
    this.errorScopeThree = error;
  }

  @Mutation
  setScopeThreeSuccess(dataScopeThree: IScopeOneThreeDataChart[][]) {
    this.dataScopeThree = dataScopeThree;
  }

  @Mutation
  setScopeThreeBaseSuccess(dataScopeThree: IScopeOneThreeDataChart) {
    this.dataScopeThreeBase = dataScopeThree;
  }

  @Action({ rawError: true })
  async fetchScopeThree(payload: IScopeOneThreeChart) {
    const serviceScopeOneAndThreeAPI = new Scope1Scope3API();
    const { data, error } =
      await serviceScopeOneAndThreeAPI.getScopeThreeEmission(payload);
    if (error) {
      this.setScopeThreeError(error);
    } else {
      this.setScopeThreeBaseSuccess(data);
      const dataConvert = covertDataChartScope1And3(data);
      return dataConvert;
    }
  }
  get dataCurrentYearScopeOneAndThreeGetter() {
    return this.currentYear;
  }

  @Mutation
  currentYearScopeOneAndThreeEmission(value: string | number | Date) {
    this.currentYear = getYear(value);
  }
  get dataCurrentFacilityScopeOneAndThreeGetter() {
    return this.facility;
  }

  @Mutation
  currentFacilityScopeOneAndThreeEmission(value: string) {
    this.facility = value;
  }

  get dataTotalCO2ScopeOneTwoThreeGetter() {
    return this.dataTotalCO2ScopeOneTwoThree;
  }
  @Mutation
  totalCO2ScopeOneTwoThree(payload: any) {
    if (
      payload.valuesScopeOne &&
      payload.valuesScopeThree &&
      payload.valuesScopeTwo
    ) {
      this.dataTotalCO2ScopeOneTwoThree =
        payload.valuesScopeOne[payload.valuesScopeOne.length - 1][1] +
        payload.valuesScopeThree[payload.valuesScopeThree.length - 1][1] +
        payload.valuesScopeTwo[payload.valuesScopeTwo.length - 1][1];
    } else {
      this.dataTotalCO2ScopeOneTwoThree = 0;
    }
  }
  @Action({ rawError: true })
  fetchAllDataChartScope1And3(payload: any) {
    this.loadingScopeOneAndThreeEmission(true);
    return Promise.all([
      this.fetchScopeOne(payload),
      this.fetchScopeThree(payload),
      ScopeTwoEmissionModule.fetchS2EmissionChart(payload),
      EmissionModule.fetchEmission(payload.Year),
      SettingBaseLineModule.getGHGTargetsLongTermAction({
        EndYear: payload.Year,
      }),
      SettingBaseLineModule.getGHGTargetsShortTermAction({
        EndYear: payload.Year,
      }),
    ]).then((values: any) => {
      this.setScopeOneSuccess(values[0]);
      this.setScopeThreeSuccess(values[1]);
      this.totalCO2ScopeOneTwoThree({
        valuesScopeOne: values[0],
        valuesScopeThree: values[1],
        valuesScopeTwo: values[2],
      });
      this.loadingScopeOneAndThreeEmission(false);
    });
  }

  @Action({ rawError: true })
  async getScopeOneHistoryAction(payload: IScopeOneScopeThreeHistoryRequest) {
    const serviceScopeOneAndThreeAPI = new Scope1Scope3API();
    const { data, error } = await serviceScopeOneAndThreeAPI.getScopeOneHistory(
      payload
    );
    if (error) {
      return null;
    } else {
      return data;
    }
  }
  @Action({ rawError: true })
  async getScopeThreeHistoryAction(payload: IScopeOneScopeThreeHistoryRequest) {
    const serviceScopeOneAndThreeAPI = new Scope1Scope3API();
    const { data, error } =
      await serviceScopeOneAndThreeAPI.getScopeThreeHistory(payload);

    if (error) {
      return null;
    } else {
      return data;
    }
  }
}

export default getModule(ScopeOneAndThreeEmissionModule);
